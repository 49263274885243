import React from "react";
import ThankYouPage from "../../components/thank-you/thank-you";
import './thank-you.scss';


export default ({location}) => {

    return (<ThankYouPage location={location}>
            <h1>Thank you!</h1>

            <p>We appreciate you reaching out to us!<br/>One of our team members will get back to you shortly.</p>
            <p>Wishing you an amazing day further!</p>
            <p><a href="/resources">View our additional Resources </a></p>
        </ThankYouPage>
    );
}